@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Work Sans';
  src: url('../../public/fonts/WorkSans-VariableFont.ttf') format('truetype');
  font-display: block;
}

/* Remove default image  */
img {
  overflow: visible;
}

a {
  color: #06b2b6;
}

a:visited {
  color: #155772;
}

@layer base {

  .type-body {
    @apply font-WorkSans text-sm md:text-base lg:text-md xl:text-lg leading-normal;
    font-variation-settings: 'wght' 300;
  }

  .type-body-bold {
    @apply type-body;
    font-variation-settings: 'wght' 500;
  }

  .type-title {
    @apply font-WorkSans text-4xl lg:text-5xl xl:text-6xl leading-none weight-700;
  }

  .type-heading {
    @apply font-WorkSans text-3xl lg:text-3xl xl:text-5xl leading-tight tracking-tight weight-700;
  }

  .type-subheading {
    @apply font-WorkSans text-md lg:text-lg xl:text-xl leading-tight;
    font-variation-settings: 'wght' 500;
  }

  .type-subscript {
    @apply font-WorkSans text-sm leading-tight;
    font-variation-settings: 'wght' 300;
  }
}

@layer utilities {
  .allow-html-styles p {
    margin-bottom: 16px;
  }

  .allow-html-styles strong {
    font-variation-settings: 'wght' 600;
  }

  .weight-700 {
    font-variation-settings: 'wght' 700;
  }

  .weight-600 {
    font-variation-settings: 'wght' 600;
  }

  .weight-500 {
    font-variation-settings: 'wght' 500;
  }

  .weight-400 {
    font-variation-settings: 'wght' 400;
  }

  .weight-300 {
    font-variation-settings: 'wght' 300;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .no-shift {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: all 0.25s;
  }

  .no-shift::after {
    content: attr(data-i18n);
    content: attr(data-i18n) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-variation-settings: 'wght' 700;
  }

  .wrapper {
    @apply container mx-auto px-6 lg:px-10;
  }
}

body { transition: background 0.4s }

.floating-border::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  display: block;
  border: 2px solid #2AAC00;
  border-radius: 12px;
  opacity: 0;
  transition: all .3s ease 0s;
  transition-property: opacity,border-color;
}

.floating-border:hover::before {
    opacity: 1;
}


.allow-html.allow-html strong { 
  @apply type-body-bold;
}

.allow-html.allow-html blockquote {
  @apply pl-4 border-l-2 border-xumoBerry text-md lg:text-xl;
}

.allow-html.allow-html blockquote em {
  @apply type-body;
}

.allow-html.allow-html p:not(:last-child), .allow-html.allow-html blockquote:not(:last-child) {
  @apply mb-4;
}

h1 sup {
  font-size: 0.25em;
  top: -2em;
}


.xumo-globe .marker {
    background-size: 512px 256px;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    border: 0px solid #fff;
    transition: border-width 0.2s;
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.2);
}

.xumo-globe .markerContainer {
    z-index: 1;
    transition: opacity 0.2s;
}

.xumo-globe .markerContainer.occluded {
    opacity: 0;
}

.xumo-globe .markerContainer:hover {
    z-index: 50000000 !important;
}

.xumo-globe .markerContainer:hover .marker {
    border-width: 10px;
    transition: border-width 0.2s;
}

.xumo-globe .markerContainer:hover .markerRipple {
    display: none;
}

.xumo-globe .markerContainer:hover .tooltip {
    z-index: 50000000 !important;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}

.xumo-globe .markerContainer:hover .tooltip .fadein {
    opacity: 1;
    transform: translateY(0);
}

.xumo-globe .tooltip {
    position: absolute;
    top: -25px;
    visibility: hidden;
    opacity: 0;
    z-index: 900;
    transform: translateX(30px);
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    min-width: 12rem;
    pointer-events: none;
}

.xumo-globe .tooltip:before {
    content: '';
    position: absolute;
    top: 15px;
    left: -19px;
    width: 0;
    height: 0;
    border-color: transparent #fff transparent transparent;
    border-width: 10px;
    border-style: solid;
}

.xumo-globe .tooltip h3 {
    margin: 0;
    font-weight: normal;
    font-size: 1.75rem;
}

.xumo-globe .tooltip .number {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.xumo-globe .tooltip .icons {
    padding: 1rem 0;
    display: grid;
    row-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.xumo-globe .tooltip .icons .icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #ddd;
}

.xumo-globe .tooltip .btn {
    background-color: #155772;
    color: white;
    font-size: 0.65rem;
    border-radius: 4px;
    padding: 0.5rem;
    display: block;
    text-decoration: none;
}

.xumo-globe .markerRipple {
    z-index: 0;
    animation: ripple 2s infinite ease-out;
    pointer-events: none;
}

.xumo-globe .center {
  pointer-events: none;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale3d(1.5, 1.5, 1);
    }
}


.cog .cog-rotate {
  animation-name: cog-rotation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.cog:nth-child(2n) .cog-rotate {
  animation-direction: reverse;
}

@keyframes cog-rotation {
  from { 
      transform: rotate(0deg);
  }

  to { 
      transform: rotate(360deg);
  }
}

